/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.rect {
	text-align: center;
	font-size: 30px;
	padding-top: 10px;
	height: 100%;
}

.demo-light {
	background: rgba(245, 229, 166, 0.5)
}

.demo-dark {
/*	background: rgba(148, 215, 199, 0.5);*/
}

	.demo-dark.header {
/*		background: rgba(243, 158, 108, 0.5);*/
	}

	.demo-dark.footer {
		background: rgba(123, 155, 207, 0.5);
	}

.small {
	height: 50px;
	border: 1px solid lightgray;
}
